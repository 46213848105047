.blog-container {
    /* background-color: #667371; */
    background: linear-gradient(to bottom left, #104c70, #021622);

    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 20px;

    margin-bottom: 20px;
    margin-top: 20px;

    border-radius: 5px;
    box-shadow: -2px 4px 4px #000;
}

.blog-title-container {
    text-align: center;
}

.blog-title {
    margin-bottom: 20px;
    color: #F2F0EB;
    /* Add margin-bottom for spacing */
}

.MuiCard-root.blog-card {
    border-radius: 5px !important;
}