/* styles.css */
.MuiToolbar-root {
    /* background-color: #021622 !important; */
    background: linear-gradient(to right, #021622, #104c70);
    /* Define your gradient colors */
}

.nav-link {
    color: white !important;
}

.nav-button {
    padding-left: 5px;
    padding-right: 5px;
}