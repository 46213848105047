.hero-section {
    position: relative;
    width: 100%;
    height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../assets/Logo-trans.png') no-repeat center/50%;
    margin: 0 auto;
    background-color: #023059;
}

.hero-text {
    text-align: center;
    vertical-align: bottom;
    color: white;
    color: white;
    /* Adjust text color */
    text-shadow: 2px 2px 4px #000;
    /* Add colored outline */
}

.tagline {
    font-size: 32px;
    margin-bottom: 20px;
}


.hero-content-wrapper {

    width: 100%;
}

.cta-button {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.cta-button:hover {
    background-color: #0056b3;
}